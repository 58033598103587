import * as React from 'react';
import './404.less';
import cloud from './../images/404ImagePage.png';
import person from './../images/404PersonImage.png';
import { Typography, Button } from 'antd';
import { Link } from 'gatsby';

const {Title , Paragraph} = Typography

const NotFoundPage = () => {
  return (
    <main className='page-wrapper-404'>
      <div className='container'>
        <div className="cloud-wrapper">
          <div className='four-pointed-star-small position-small-star-1'></div>
          <div className='four-pointed-star-small position-small-star-2'></div>
          <div className='four-pointed-star-small position-small-star-3'></div>
          <div className='four-pointed-star-small position-small-star-4'></div>
          <div className='four-pointed-star-small position-small-star-5'></div>
          <div className='four-pointed-star-small position-small-star-6'></div>
          <div className='four-pointed-star-small position-small-star-7'></div>
          <div className='four-pointed-star-large postion-large-star-1'></div>
          <div className='four-pointed-star-large postion-large-star-2'></div>
          <div className='four-pointed-star-large postion-large-star-3'></div>
          <div className='four-pointed-star-large postion-large-star-4'></div>
          <div className='four-pointed-star-large postion-large-star-5'></div>
          <div className='four-pointed-star-large postion-large-star-6'></div>
          <img src={cloud} className='cloud'/>
          <div className='text'>
            <Title level={3} className='cloud-text'>404</Title>
            <Title level={3} className='cloud-text'>Przepraszamy, coś się nam zapodziało.</Title>
            <Paragraph className='cloud-text small-text'>Przepraszamy, odwiedzona strona nie istnieje.</Paragraph>
          </div>
        </div>
        <div className="person-wrapper">
          <img src={person} className='person'/>
          <Button className='button-404'><Link to='/' className='link-404'>Cofnij się</Link></Button>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
